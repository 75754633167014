// Head over to the Credentials section in your app on CometChat Dashboard and where you can find your unique App ID, Region and Auth Key. 😊

// export const AppConstants = {
//   APP_ID: "26278087c1553b27",
//   REGION: "US",
//   AUTH_KEY: "c00cab364d05db25606fe80d3bdbbac061d83b48",
// };

export const AppConstants = {
  APP_ID: "26768309dc0ff3d6",
  REGION: "in",
  AUTH_KEY: "976413a06383af444b51a23a62e30625b1d2c612",
};